<template>
  <div>
    <!-- <div
      id="map"
      style="
        position: absolute;
        left: 0px;
        right: 0px;
        width: 800px;
        height: 500px;
      "
    ></div> -->

    <div id="map" style="width: 100%; height: 800px"></div>
    <!-- <iframe
      src="https://www.supermapol.com/apps/dataviz/view.html?id=663860993"
      width="100%"
      height="550px"
    ></iframe> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.mapInit();
  },
  methods: {
    mapInit() {
      // var host = window.isLocal ? window.server : "https://iserver.supermap.io";
      // var map,
      //   url = host + "/iserver/services/map-ndvi/rest/maps/ndvi";

      // // 初始化地图信息
      // var map = L.map("map", {
      //   crs: L.CRS.EPSG4326,
      //   center: [0, 0],
      //   maxZoom: 18,
      //   zoom: 1,
      // });
      // new L.supermap.TiledMapLayer(url).addTo(map);
      // new ol.supermap.WebMap(1584343797);

      // service.then(function (services) {
      //   // var service = services[0];
      //   // service.updateDataInfo();
      //   console.log(services, "online");
      // });map_poi_13_nj6x67di
      // var map,
      //   url =
      //     "https://www.supermapol.com/proxy/ufg6y1hg/iserver/services/map_poi_13_nj6x67di/rest"; //<!--此处为您调用的的REST地图服务的地址-->。
      // map = new ol.Map({
      //   target: "map",
      //   controls: ol.control
      //     .defaults({ attributionOptions: { collapsed: false } })
      //     .extend([new ol.supermap.control.Logo()]),
      //   view: new ol.View({
      //     center: [108.55, 29.86],
      //     zoom: 8,
      //     projection: "EPSG:4326",
      //     multiWorld: true,
      //   }),
      // });

      // var layer = new ol.layer.Tile({
      //   source: new ol.source.TileSuperMapRest({
      //     url: url,
      //     wrapX: false,
      //   }),
      //   projection: "EPSG:4326",
      // });
      // map.addLayer(layer);
      // map.addControl(new ol.supermap.control.ScaleLine());
       var map, url = "https://www.supermapol.com/proxy/iserver/services/map_chongqing_qy6yrsa0/rest/maps/ChongQing1"; //<!--此处为您调用的的REST地图服务的地址-->。
        map = new ol.Map({
            target: 'map',
            controls: ol.control.defaults({attributionOptions: {collapsed: false}})
            .extend([new ol.supermap.control.Logo()]),
            view: new ol.View({
                center: [108.55, 29.86],
                zoom: 8,
                projection: 'EPSG:4326',
                multiWorld: true

            })
        });

    var layer = new ol.layer.Tile({
            source: new ol.source.TileSuperMapRest({
                url: url,
                wrapX: false,
            }),
            projection: 'EPSG:4326'
        });
        map.addLayer(layer);
        map.addControl(new ol.supermap.control.ScaleLine());
      // var host = window.isLocal ? window.server : "https://iserver.supermap.io";
      // var map,
      //   url = host + "/iserver/services/map_China/rest/maps/China_Light";
      // map = new ol.Map({
      //   target: "map",
      //   controls: ol.control
      //     .defaults({ attributionOptions: { collapsed: false } })
      //     .extend([new ol.supermap.control.Logo()]),
      //   view: new ol.View({
      //     center: [0, 0],
      //     zoom: 2,
      //     projection: "EPSG:4326",
      //     multiWorld: true,
      //   }),
      // });
      // SuperMap.SecurityManager.registerKey(url, "ywogzG7rwQsEkZCbefppTv2e"); //<!--此处需要替换为您针对服务申请的秘钥key-->

      // var layer = new ol.layer.Tile({
      //   source: new ol.source.TileSuperMapRest({
      //     url: url,
      //     wrapX: true,
      //     serverType: "ONLINE", //<!--使用SuperMap Online申请的key时需要定义Type-->
      //   }),

      //   projection: "EPSG:4326",
      // });
      // map.addLayer(layer);
      // map.addControl(new ol.supermap.control.ScaleLine());
    },
  },
};
</script>

<style></style>
